import React from 'react';
import VideoSection from 'components/common/VideoSection';

const video = {
  filename: 'videos/thumbnails/consulter-other-mia-co.png',
  alt: '',
  video: 'https://www.youtube.com/embed/gt_dCBBJHwY',
  intro:
    '',
};

const VideoOther = () => <VideoSection {...video} />;

export default VideoOther;
