import React from 'react';
import Man1 from 'images/men/bulle1.svg';
import Man2 from 'images/men/bulle2.svg';
import Man3 from 'images/men/bulle3.svg';

export const testimonies = [
  {
    testimony: 'J’ai décidé de prendre une consultation car je ne comprenais pas pourquoi mon partenaire ne parvenait pas à finir, à éjaculer lors de nos ébats. La sexologue a pris le temps de me rassurer, de m’expliquer le fonctionnement et de nous donner des solutions. Je me sens plus à l’écoute et rassurée suite à nos échanges, vraiment.',
    patient: 'Isma',
    city: '',
    age: '25 ans',
    Icon: Man1,
    backgroundColorClass: 'lc-light-blue',
    flower: true,
    target: false,
  },
  {
    testimony:
      'Lorsque j’ai appris que j’étais séropositive, j\'ai eu peur et j’ai mis un stop à ma sexualité, de peur de le transmettre à qui que ce soit. J’en ai eu marre de cette situation qui était très douloureuse, j’ai fait plusieurs séances pour apprendre à vivre avec le VIH et surtout, reprendre petit à petit une sexualité safe pour moi et pour un partenaire à venir. C’est très rassurant, un gros poids s’est enlevé de mes épaules.',
    patient: 'Angèle',
    city: '',
    age: '34 ans',
    Icon: Man3,
    backgroundColorClass: 'lc-light-yellow',
    flower: false,
    target: true,
  },
  {
    testimony:
      'J’ai décidé de faire des consultations avec une sexologue pour apprendre à avoir une sexualité normale malgré le handicap physique, que j’ai depuis ma naissance. Je me suis rendue compte qu’à part quelques ajustements techniques, faire l’amour est non seulement à ma portée, mais prendre beaucoup de plaisir aussi…',
    patient: 'Lara',
    city: '',
    age: '19 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: true,
    target: false,
  },
  {
    testimony:
      'Je ressens de plus en plus de pression sociale, mes amies se marient, ont des enfants etc. Je suis l’éternelle célibataire du groupe et je n’ai même pas envie d’avoir des rapports avec qui que ce soit. Je travaille actuellement avec la sexologue qui me suit pour savoir si c’est un blocage, de l’asexualité ou juste une manière différente de voir les choses. L’exploration de moi-même en somme ! Merci à Mia de permettre ça.',
    patient: 'Ornella',
    city: '',
    age: '30 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: false,
    target: true,
  },
];

export const sections = [
  {
    title:
      '30 % des femmes ont déjà eu mal lors des rapports',
    body: (
      <div>
        <p>
          Un chiffre qui laisse perplexe, mais qui pourtant reflète la réalité : la pénétration
          peut être douloureuse, même si elle ne devrait pas l’être. D’où viennent alors ces
          douleurs autour de la pénétration ?
        </p>
        <p>
          Si le rôle du partenaire est évidemment central, ainsi que la communication et l’écoute de
          l’autre, les douleurs à la pénétration ont parfois des causes psychosomatiques ou
          médicales : on parle alors fréquemment de
          {' '}
          <b>dyspareunies</b>
          {' '}
          ou de
          {' '}
          <b>vaginisme</b>
          .
        </p>
        <p>
          Pourtant, les femmes restent bien souvent seules dans leur souffrance : Mia les accompagne
          à mieux exprimer leur sexualité, en commençant par pouvoir en parler librement avec des
          sexologues spécialistes de la sexualité des femmes.
        </p>
        <p>
          Et contre une idée reçue parmi tant d’autres, non, la pénétration ne doit pas faire mal,
          et il existe des solutions.
        </p>
      </div>
    ),
  },
  {
    title:
      '78% des femmes ont déjà eu des difficultés à atteindre l’orgasme',
    body: (
      <div>
        <p>
          De nombreuses femmes éprouvent des difficultés à prendre du plaisir ou atteindre l'orgasme
          : on parle même parfois d'anorgasmie ou l’absence d’orgasme. Et malheureusement, de trop
          nombreux tabous ou préjugés perdurent encore autour de la sexualité.
        </p>
        <p>
          Pratiques sexuelles « phallo-centrées », manque de communication entre les partenaires,
          vision erronée de la sexualité ou méconnaissance du corps féminin, l’accès au plaisir ne
          va pas de soi pour toutes les femmes.
        </p>
        <p>
          Malgré une certaine libération du discours autour de la sexualité – et notamment de la
          masturbation féminine – l’absence de plaisir (ou des difficultés à atteindre l’orgasme)
          s’accompagne souvent de difficultés personnelles et émotionnelles : les sexologues de
          Mia.co sont donc spécialement formés pour répondre à toutes ces questions.
        </p>
        <p>
          Après avoir écarté certains facteurs médicaux assez rares, le(la) sexologue pourra
          identifier clairement avec vous si l’origine du problème se situe ailleurs.
        </p>
        <p>
          De nombreux facteurs psychologiques peuvent par exemple interférer avec la sexualité ;
          tout comme l’éducation sexuelle est fondamentale pour vivre une sexualité épanouie.
          L’important est de pouvoir en parler librement et briser certains tabous.
        </p>
      </div>
    ),
  },
  {
    title:
      '10 à 15 % des femmes sont concernées par la baisse de libido',
    body: (
      <div>
        <p>
          Il est difficile de définir clairement et de façon universelle une baisse de libido chez
          les femmes ; précisément parce que toutes les femmes n’ont pas la même libido et les mêmes
          attentes de la sexualité.
        </p>
        <p>
          De nos jours, les sexologues sont sensibles à deux critères : premièrement, la notion de
          souffrance, qui peut accompagner la plainte d’une patiente, et deuxièmement, l’importance
          de prendre en considération ce ressenti.
        </p>
        <p>
          Et comme le soulignent les sexologues, avant de vouloir soigner une baisse de libido,
          il faut en soigner les causes, et s'intéresser à tout ce qui peut la perturber.
        </p>
        <p>
          Beaucoup de conditions particulières peuvent augmenter la fréquence des troubles sexuels
          féminins (ménopause, endométriose, post-partum, maladies neurologiques, traitements des
          maladies chroniques, etc.).
        </p>
        <p>
          Fort de l’expérience psychologique et médicale de ses sexologues, l’équipe de Mia.co
          s’appuie sur les dernières études scientifiques afin d'accompagner ses patientes, et de
          leur proposer des méthodes efficaces de téléconsultation.
        </p>
      </div>
    ),
  },
  {
    title:
      'Comment réagir face aux problèmes de couple ?',
    body: (
      <div>
        <p>
          Vivre à deux demande du temps, de la compréhension, de l’affection et de la bienveillance
          ; il est normal de vivre des crises. Tous les couples en vivent.
        </p>
        <p>
          Mais lorsque les disputes deviennent quotidiennes, elles peuvent finir par se cristalliser
          à l’histoire du couple, au partenaire, avec des conséquences souvent tristes et
          douloureuses.
        </p>
        <p>
          Si la thérapie de couple apporte des résultats toujours positifs – la continuité du couple
          ou une séparation sans rancœur – n’attendez pas l’implosion pour réagir : il n’existe pas
          de « dernière chance », mais seulement des meilleures réponses au quotidien.
        </p>
        <p>
          On apprend tous les jours ! Vivre seule comporte son lot de difficultés, tout comme vivre
          en couple apporte certaines contraintes : l’important est de se souvenir pourquoi on est
          en couple avec cette personne.
        </p>
        <p>
          Les psychologues et les sexologues de Mia.co peuvent vous aider à retrouver du sens.
        </p>
      </div>
    ),
  },
  {
    title:
      'Bien-être global et bien-être sexuel : deux états intimement liés ',
    body: (
      <div>
        <p>
          Depuis quelques années, nous savons que le bien-être sexuel a une forte incidence sur le
          bien-être global d’une personne. Et que par conséquent, les troubles sexuels peuvent être
          à l’origine de plusieurs difficultés psychologiques.
        </p>
        <p>
          Si la sexualité est encore trop largement victime des tabous qui l’entourent ou des
          fausses croyances, la médecine sexuelle n’a cessé d’évoluer, démocratisant les questions
          liées à l’intime, l’accès aux soins de qualité, et à plus grande échelle, la diffusion
          d’une meilleure éducation sexuelle.
        </p>
        <p>
          Reconnue aujourd’hui comme un enjeu de santé publique, la sexualité se libère
          progressivement des mythes autour de la performance et de la course à l’orgasme : nous
          avons toutes et tous des attentes différentes, et des sexualités différentes.
        </p>
        <p>
          Les sexologues sont là pour être à l’écoute, libérer certaines paroles, rappeler les
          fondements de l’intimité et la place de la sexualité dans nos vies.
        </p>
        <p>
          Si comme Mia.co, vous pensez que chacun a le droit de vivre sa sexualité comme il ou elle
          lui plaît, et que le bien-être sexuel est à la portée de toutes et tous, nos sexologues
          sont là pour vous guider.
        </p>
      </div>
    ),
  },
  {
    title:
      'Comment aborder les difficultés sexuelles',
    body: (
      <div>
        <p>
          Aborder des difficultés sexuelles est un geste courageux : cela touche à notre intimité,
          à nos projections, à nos angoisses et parfois même, à nos peurs. Pourtant, de plus en
          plus de spécialistes s’intéressent à ces questions.
        </p>
        <p>
          Le plus difficile étant de faire le premier pas, et d’ouvrir le dialogue.
        </p>
        <p>
          Les problèmes sexuels peuvent concerner toutes les personnes, de tous les âges. Ils ont
          parfois des répercussions sur le bien-être général, mais aussi dans nos rapports aux
          autres, et nos relations de couple.
        </p>
        <p>
          Si aborder ses difficultés sexuelles avec son ou sa partenaire est là aussi un moment
          délicat, une meilleure communication éviterait pourtant de laisser un mal-être
          s’installer.
        </p>
        <p>
          Peu importe votre âge, votre statut, votre genre, votre orientation sexuelle, les
          sexologues de Mia.co accordent une attention particulière à toutes les difficultés
          sexuelles : il n’existe pas de « norme » en sexualité, et aujourd’hui, la sexologie permet
          de trouver une solution à chaque problème.
        </p>
        <p>
          À partir du moment où l’on est prête à franchir le pas de la consultation.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quels sont les avantages de Mia.co ?',
    body: (
      <div>
        <p>
          • Une équipe de praticiennes (sexologues, sages-femmes, thérapeutes de couple)
          spécialistes de la sexualité féminine et spécialement formées à la pratique de la
          téléconsultation ;
        </p>
        <p>
          • Un comité scientifique composé de médecins sexologues, de professeures d’université en
          sexologie et de membres dirigeants d’associations de sexologues ;
        </p>
        <p>
          • Une consultation de 30 min à 45 euros (les prix en cabinet varient de 80 à 100 euros) ;
        </p>
        <p>
          • La livraison de produits d’accompagnement en 24 ou 48H.
        </p>
      </div>
    ),
  },
];

