import React, { useState } from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';

import Container from 'react-bootstrap/Container';
import { Layout } from 'components/common';
import TextSectionList from 'components/common/TextSectionList';
import Section from 'components/common/Section';
import TestimoniesSection from 'components/common/TestimoniesSection';
import Image from 'components/common/utils/Image';

import StyledLink from 'components/common/utils/StyledLink';

import VideoOther from '../common/VideoOther';

import { testimonies, sections } from './data';

import './styles.sass';

import HowItWorksSection from '../HowItWorksSection';
import Collapse from 'react-bootstrap/Collapse';
import Hush from 'images/icons/hush.svg';
import Medal from 'images/icons/medal.svg';
import PharmaCross from 'images/icons/pharma-cross-with-heart.svg';
import TherapistCarousel from '../common/TherapistsCarousel';

const IndexPage = () => {
  const [open, setOpen] = useState(false);
  return (
    <Layout
      footerCtaPath="/consulter-autres-problemes/"
      showLinksInFooter={false}
      backgroundBeforeFooter="background-color-pale-yellow"
    >
      <CharlesMeta
        data={{
          title: 'Vous avez des questions de sexualité ? Consultez une spécialiste de la santé sexuelle',
          description:
            "Libido, plaisir, douleurs, traumatismes, questions ? Consultez en ligne une thérapeute de manière confidentielle par vidéo, téléphone ou messagerie 7 jours sur 7",
        }}
      />
      <div className="text-left landing-page">
        <Section className="text-white d-flex flex-column background-color-pale-yellow home-top-section patho-top-section other-top-section m-lg-0">
          <h1 className="h1 text-center main-title-patho align-self-center d-lg-none mb-1">
            Un questionnement&nbsp;? Et si vous en parliez à une sexologue&nbsp;?
          </h1>
          <div className="desktop-home-top-div desktop-patho-top-div d-none d-lg-flex">
            <div className="home-top-left">
              <div>
                <h1 className="h1-patho-top-left">
                  Un questionnement&nbsp;?
                  <br />
                  Et si vous en parliez à une sexologue&nbsp;?
                </h1>
                <div className="d-flex cta-div">
                  <StyledLink
                    variant="orange"
                    path="/consulter-autres-problemes/"
                    className=""
                    margin
                  >
                    CONSULTER
                  </StyledLink>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column desktop-videoPL justify-content-lg-center align-items-lg-end">
              <VideoOther className="" />
              <span className="video-patho-text">
                Je suis Camille Bataillon, la sexologue qui a créé ce protocole de
                téléconsultation.
              </span>
            </div>
          </div>
          <div className="info-div info-div-patho">
            <div className="d-flex justify-content-center d-lg-none">
              <div className="d-flex cta-div">
                <StyledLink
                  variant="orange"
                  path="/consulter-autres-problemes/"
                  className=""
                >
                  CONSULTER
                </StyledLink>
              </div>
            </div>
            <div className="info-list info-list-patho">
              <div className="text-left">
                <div className="img-wrapper"><Hush /></div>
                <span className="hush-span">CONFIDENTIEL & SÉCURISÉ</span>
              </div>
              <div>
                <div className="img-wrapper"><Medal /></div>
                <span className="medal-span">SEXOLOGUES DIPLÔMÉES</span>
              </div>
              <div>
                <div className="img-wrapper"><PharmaCross /></div>
                <span className="pharmacross-span">SUIVIS & ASSISTANCE ILLIMITÉS</span>
              </div>
            </div>
          </div>
          <Container className="d-flex flex-column align-items-center video-patho-container d-lg-none">
            <VideoOther />
            <span className="video-patho-text">
              Je suis Camille Bataillon, la sexologue qui a créé ce protocole de téléconsultation.
            </span>
          </Container>
        </Section>
        <HowItWorksSection />
        <Section className="d-flex flex-column flex-lg-row patho-explanation-section justify-content-lg-center align-items-lg-center">
          <div className="d-none d-lg-flex patho-explanation-left">
            <Image filename="other.png" className="patho-img explainations-img-purple" />
          </div>
          <div className="d-flex flex-column patho-explanation-right">
            <h2 className="section-title h2-title patho-title">
              Comment aborder les difficultés sexuelles&nbsp;?
            </h2>
            <Container className="patho-container">
              <div className="patho-paragraph">
                <p>
                  Aborder des difficultés sexuelles est un geste courageux : cela touche à notre
                  intimité, à nos projections, à nos angoisses et parfois même, à nos peurs.
                  Pourtant, de plus en plus de spécialistes s’intéressent à ces questions. Le plus
                  difficile étant de faire le premier pas, et d’ouvrir le dialogue.
                </p>
                <p>
                  Les problèmes sexuels peuvent concerner toutes les personnes, de tous les âges.
                  Ils ont parfois des répercussions sur le bien-être général, mais aussi dans nos
                  rapports aux autres, et nos relations de couple.
                </p>
                <p>
                  Si aborder ses difficultés sexuelles avec son ou sa partenaire est là aussi un
                  moment délicat, une meilleure communication éviterait pourtant parfois de laisser
                  un mal-être s’installer.
                </p>
                <p className="d-none d-lg-block">
                  Peu importe votre âge, votre statut, votre genre, votre orientation sexuelle,
                  les sexologues de Mia.co accordent une attention particulière à toutes les
                  difficultés sexuelles : il n’existe pas de « norme » en sexualité, et
                  aujourd’hui, la sexologie permet de trouver une solution à chaque problème.
                </p>
                <Collapse in={open} className=" d-lg-none">
                  <div id="example-collapse-text">
                    <p>
                      Peu importe votre âge, votre statut, votre genre, votre orientation sexuelle,
                      les sexologues de Mia.co accordent une attention particulière à toutes les
                      difficultés sexuelles : il n’existe pas de « norme » en sexualité, et
                      aujourd’hui, la sexologie permet de trouver une solution à chaque problème.
                    </p>
                  </div>
                </Collapse>
                <div className="d-flex flex-column d-lg-none">
                  {open === false ? (
                    <span
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      className="read-more align-self-end"
                    >
                      Lire la suite
                    </span>
                  ) : (
                    <span
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      className="read-more align-self-end"
                    >
                      Voir moins
                    </span>
                  )}
                </div>
              </div>
            </Container>
            <div className="d-flex cta-div justify-content-center justify-content-lg-start">
              <StyledLink
                variant="orange"
                path="/consulter-autres-problemes/"
                className=""
                margin
              >
                CONSULTER
              </StyledLink>
            </div>
          </div>
        </Section>
        <Section className="d-flex flex-column therapist-carousel-section">
          <div className="d-flex flex-column therapist-div-wrapper">
            <h2 className="h2-title text-white text-center">DES EXPERTES POUR VOUS ACCOMPAGNER</h2>
            <TherapistCarousel />
          </div>
        </Section>
        <TestimoniesSection testimonies={testimonies} path="/consulter-autres-problemes/" />
        <Section className="d-flex flex-column align-items-center faq-section">
          <Container className="d-flex flex-column align-items-center">
            <h3 className="section-title text-center">
              FAQ
            </h3>
            <TextSectionList sections={sections} />
          </Container>
        </Section>
      </div>
    </Layout>
  );
};

export default IndexPage;
